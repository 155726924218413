import React from 'react';
import RootRouter from './routes';
import './App.css';
import Intercom from '@intercom/messenger-js-sdk';
/**
 * This is main App function component
 * @returns 
 */
function App() {
    Intercom({
        app_id: 'x65cwe4a',
    });
    return (
        <div className="App">
            <RootRouter />
        </div>
    );
}

export default App;
